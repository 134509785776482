/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.form-error-msg {
    color: red;
    margin-bottom: 10px;
    margin-top: 8px;
}

.inputpincale{
  border: 1px solid;
  margin-top: 8px;
}

.ft-30{
  font-size: 30px;
}

.action-btn-icon{
  padding:10px;
  position: absolute;
  right: 0;
}

.margin-0{
  margin: 0 !important;
}

.p-l-r-10{
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.m-l-r-5{
  margin-left: 5px!important;
  margin-right: 5px!important;
}
ion-button .add_btn {
  border-radius: 50px !important;
}

ion-alert{
  textarea{
    min-height: 120px !important;
  }
}

.ion-float-right {
  float: right !important;
  border-radius: 50px !important;
  padding: 5px 10px !important;
}

@media(max-width: 325px)  {
  .clndr_block span {
    border: 0px solid;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 4px 0;
    color: var(--theme-secondary-color);
    border-radius: 100%;
    font-size: 11px;
}
}
